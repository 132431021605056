<template>
  <div class="tabs-adr bg">
    <v-tabs
      v-model="tab"
      background-color="#e9e9e9"
      show-arrows
    >
      <v-tab>Описание</v-tab>
      <v-tab>Инструкции</v-tab>
      <v-tab>Условия обучения</v-tab>
    </v-tabs>
    
    <v-divider></v-divider>

    <v-tabs-items v-model="tab">

      <v-tab-item>
        <v-card flat>
          <div class="content">
            <p><strong>Подготовка водителей механических транспортных средств для перевозки опасных грузов.</strong></p>
            <p>Водители, осуществляющие перевозки опасных грузов автомобильным транспортом должны иметь свидетельство ДОПОГ (ADR), для получения которого необходимо пройти обучение и сдать экзамен в комиссии Госпромнадзора. Срок действия свидетельства - 5 лет.</p>
            <p><strong>Обучение проводится по базовой программе и при необходимости по курсу перевозки опасных грузов в цистернах.</strong></p>
            <v-img
              src="img/doc/dopog.png"
              max-width=250
            ></v-img>
            <p>Водителям, осваивающим образовательную программу, выдаются: <u>ПИСЬМЕННЫЕ ИНСТРУКЦИИ В СООТВЕТСТВИИ С ДОПОГ</u> и учебно-практическое пособие <u>"ПЕРЕВОЗКА ОПАСНЫХ ГРУЗОВ В МЕЖДУНАРОДНОМ СООБЩЕНИИ".</u></p>
            <p><v-icon left>mdi-alert-box</v-icon>Успешное освоение <u>базовой программы</u> позволит водителям перевозить опасные грузы классов 2, 3, 4.1, 4.2, 4.3, 5.1, 5.2, 6.1, 6.2, 8, 9 <u>в упаковках, контейнерах, баллонах и т.д.</u></p>
            <p><v-icon left>mdi-alert-box</v-icon>Успешное освоение <u>курса перевозки опасных грузов в цистернах</u> позволит водителям перевозить опасные грузы классов 2, 3, 4.1, 4.2, 4.3, 5.1, 5.2, 6.1, 6.2, 8, 9 <u>в цистернах</u>.</p>
            <p>По окончании курсов выдается справка об обучении.</p>
            <p><v-icon left>mdi-alert-box</v-icon>Для получения Свидетельства ДОПОГ (ADR) после обучения слушатели должны сдать экзамен в территориальной комиссии Госпромнадзора.</p>
          </div>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <div class="content">
            <p><strong>Требования к водителям для зачисления в учебную группу</strong></p>
            <ul>
              <li>граждане Республики Беларусь или лица, имеющие вид на жительство или удостоверение беженца.</li>
              <li>не моложе 21 года.</li>
              <li>имеющие стаж управления транспортным средством категории «В», «С», «В» и «С», составами транспортных средств категорий «ВЕ» и «СЕ» не менее 3-х лет.</li>
            </ul>
            <br>
            <p><strong>Документы для зачисления</strong></p>
            <ul>
              <li>документ, удостоверяющий личность.</li>
              <li>одна цветная фотография размером 30 х 40 мм</li>
            </ul>
          </div>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <div class="content">
            
            <table class="table">
              <thead>
                <tr>
                  <th colspan="2">Слушатели</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Категория слушателей</td>
                  <td>водители</td>
                </tr>
              </tbody>
              <thead>
              <tr>
                <th colspan="2">Программа</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Тип программы</td>
                <td>обучающие курсы</td>
              </tr>
              <tr>
                <td>Срок обучения</td>
                <td>5 дней (базовая программа)<br>3 дня (перевозка опасных грузов в цистернах)</td>
              </tr>
              <tr>
                <td>Форма итоговой аттестации</td>
                <td>проверка знаний</td>
              </tr>
              <tr>
                <td>Выдаваемые документы</td>
                <td>справка об обучении</td>
              </tr>
            </tbody>
            </table>
            
          </div>
        </v-card>
      </v-tab-item>

    </v-tabs-items>

  </div>
</template>

<script>
export default {
  data:() => ({
    tab: null,
    items: ['Описание', 'Инструкции', 'Условия обучения'],
  })
}
</script>

<style lang="scss" scoped>
  .tabs-adr {
    padding: 10px;
    .content {
      padding: 19px 10px;
      text-align: left;
      color: #696969;
      i {
        margin-bottom: 0px;
      }
    }
    .v-tab {
      font-weight: 600;
      color: #000000 !important;
      &.v-tab--active {
        color:#f27937 !important;
      }
    }
    .v-card {
      background-color: #e9e9e9;
      &.v-sheet {
        border-radius: 0px;
      }
    }
    .theme--light.v-tabs-items {
      background-color: #D3D3D3;
    }
    .table {
      width: 100%;
      border-collapse: collapse;
      th, td {
        padding: 10px 15px;
        border: 1px solid #D3D3D3;
      }
      thead {
        background-color: #DCDCDC;
      }
      tbody {
        td {
          &:nth-child(1) {
            width: 25%;
          }
        }
      }
    }
  }
</style>
