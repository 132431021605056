<template>
  <div class="container">
    <script type='application/ld+json' v-text='jsonld'></script>
    <div class="txt-item menu-margin">
      <h2>ПЕРЕВОЗКА ОПАСНЫХ ГРУЗОВ</h2>
      <p>Водитель - перевозчик опасных грузов – это водитель грузового транспортного средства, который перевозит горючие, взрывоопасные вещества, кислоты, химически - агрессивные и другие опасные вещества или изделия. Он должен в совершенстве управлять автомобилем, знать правила перевозок опасных грузов и обладать необходимыми навыками.</p>
      <p>В соответствии с пунктами 391-392 Правил по обеспечению безопасности перевозки опасных грузов автомобильным транспортом в Республике Беларусь водители, осуществляющие перевозку опасных грузов автомобильным транспортом, должны проходить подготовку в обучающих организациях и иметь свидетельство ДОПОГ (ADR).</p>
      <p><strong>Опасные грузы это:</strong></p>
      <ul>
        <li>Взрывчатые вещества и изделия;</li>
        <li>Легковоспламеняющиеся газы;</li>
        <li>Невоспламеняющиеся, нетоксичные газы;</li>
        <li>Токсичные газы;</li>
        <li>Легковоспламеняющиеся жидкости;</li>
        <li>Легковоспламеняющиеся твердые вещества, самореактивные вещества, полимеризующиеся вещества и твердые десенсибилизированные взрывчатые вещества;</li>
        <li>Вещества, способные к самовозгоранию;</li>
        <li>Вещества, выделяющие легковоспламеняющиеся газы при соприкосновении с водой;</li>
        <li>Окисляющие вещества;</li>
        <li>Органические пероксиды;</li>
        <li>Токсичные вещества;</li>
        <li>Инфекционные вещества;</li>
        <li>Радиоактивные материалы;</li>
        <li>Коррозионные вещества;</li>
        <li>Прочие опасные вещества и изделия.</li>
      </ul>
    </div>
    <div class="adr">
      <div class="citem grid">
        <div class="c-item bg">
          <v-img
            src="img/logo/adr-1.png"
            max-width=130
          ></v-img>
          <p><strong>Базовая программа</strong></p>
          <span>Стоимость - <strong>{{getCost.adr[0].base}} BYN</strong></span><br>
          <span>Продолжительность - {{getCost.adr[0].day}} дней</span>
        </div>
        <div class="c-item bg">
          <v-img
            src="img/logo/adr-2.png"
            max-width=130
          ></v-img>
          <p><strong>Перевозка опасных грузов в цистернах</strong></p>
          <span>Стоимость - <strong>{{getCost.adr[1].tank}} BYN</strong></span><br>
          <span>Продолжительность - {{getCost.adr[1].day}} дня</span>
          <!-- <v-btn color="#D3D3D3">записаться</v-btn> -->
        </div>
      </div>
    </div>
    <div class="doc">
      <TabsAdr/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import TabsAdr from '@/components/TabsAdr.vue'
export default {
  components: {TabsAdr},
  metaInfo: {
    title: 'Курсы по перевозка опасных грузов (ADR)',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Курсы по перевозка опасных грузов (ADR) в Витебске. АДР витебск. Обучение опасные грузы',
      }
    ]
  },
  data: () => ({
    jsonld: {
      "@context": "http://www.schema.org",
      "@type": "LocalBusiness",
      "name": "ADR - ВитАвтоУниверсал",
      "url": "http://by.vit-avto.by/adr",
      "logo": "http://by.vit-avto.by/img/logo/logoB.png",
      "description": "Курсы по перевозка опасных грузов (ADR) в Витебске. АДР витебск. Обучение опасные грузы",
      "image": "http://by.vit-avto.by/img/logo/adr-1.png",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "пр-т Фрунзе, 8",
        "addressLocality": "Витебск",
        "addressCountry": "Беларусь"
      },
      "hasMap": "https://goo.gl/maps/Uwb88CPAPiHky3ye6",
      "openingHours": "Mo, Sa, Su 10:00-20:00 Tu, We, Th, Fr 09:00-20:00",
      "telephone": "+375 29 725-86-25"
    },
  }),
  computed: {
    ...mapGetters(['getCost'])
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
    })
  }
}
</script>

<style lang="scss" scoped>
  .txt-item {
    font-weight: 400;
    font-style: normal;
    background-color: #e9e9e9;
    padding: 25px;
    border-radius: 5px;
    box-shadow: -20px 20px 0 -17px #fff, 
                20px -20px 0 -17px #fff, 
                20px 20px 0 -20px rgb(165, 165, 165), 
                0 0 0 2px rgb(165, 165, 165);
  }
  .v-divider {
    margin: 25px 0;
  }
  .adr {
    h2 {
      text-align: center;
      margin: 25px 0;
    }
    .citem {
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      padding: 20px;
      grid-gap: 10px;
      align-items: center;
      justify-items: center;
      align-content: center;
      justify-content: center;
      .c-item {
        width: 100%;
        border-radius: 5px;
        text-align: center;
        padding: 25px 0;
        .v-image {
          margin: 0 auto;
        }
        .v-btn {
          margin-top: 25px;
        }
      }
      
    }
  }
  .doc {
    text-align: center;
  }
</style>
